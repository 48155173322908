import React from "react";
import {
  chakra,
  Box,
  Flex,
  useColorModeValue,
  VisuallyHidden,
  HStack,
  Button,
  useDisclosure,
  VStack,
  IconButton,
  CloseButton,
} from "@chakra-ui/react";
import Logo from "./logo";
import { AiOutlineMenu } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";


export default function Navbar() {
  const bg = useColorModeValue("white", "gray.800");
  const mobileNav = useDisclosure();
  let navigate = useNavigate();

  return (
    <React.Fragment>
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100%"
      backgroundColor="#f8f8f8"
      padding="0px"
      zIndex="999" // Ensure the navbar appears above other content
    >
      <chakra.header
        bg={bg}
        w="full"
        px={{ base: 2, sm: 4 }}
        py={4}
        shadow="md"
      >
        <Flex alignItems="center" justifyContent="space-between" mx="auto">
          <Flex>
            <chakra.a
              href="/"
              title="Nabori Home Page"
              display="flex"
              alignItems="center"
            >
              <Box w="150px" h="50px">
                <Logo />
              </Box>
              <VisuallyHidden>Nabori Labs</VisuallyHidden>
            </chakra.a>
          </Flex>
          <HStack display="flex" alignItems="center" spacing={1}>
            <HStack
              spacing={1}
              mr={1}
              color="purple.500"
              display={{ base: "none", md: "inline-flex" }}
            >
              <Button variant="ghost" onClick={() => {
                      navigate("/login")
                    }}>Login</Button>
            </HStack>
            <Link to="/request-demo">
                <Button colorScheme="blue" size="md" rounded="full">
                    Solicitar Demo
                </Button>
            </Link>
            <Box display={{ base: "inline-flex", md: "none" }}>
              <IconButton
                display={{ base: "flex", md: "none" }}
                aria-label="Open menu"
                fontSize="20px"
                color={useColorModeValue("gray.800", "inherit")}
                variant="ghost"
                icon={<AiOutlineMenu />}
                onClick={mobileNav.onOpen}
              />
              <VStack
                pos="absolute"
                top={0}
                left={0}
                right={0}
                display={mobileNav.isOpen ? "flex" : "none"}
                flexDirection="column"
                p={2}
                pb={4}
                m={2}
                bg={bg}
                spacing={3}
                rounded="sm"
                shadow="sm"
              >
                <CloseButton
                  aria-label="Close menu"
                  onClick={mobileNav.onClose}
                />
                <Button w="full" variant="ghost">
                  Compañía
                </Button>
                <Button w="full" variant="ghost" onClick={() => {
                      navigate("/login")
                    }}>
                  Login
                </Button>
              </VStack>
            </Box>
          </HStack>
        </Flex>
      </chakra.header>
    </Box>
    </React.Fragment>
  );
}