import React from "react";
import Navbar from "../components/navbar";
import { Box } from "@chakra-ui/react";
import Hero from "../components/hero";
import CTA from "../components/call_to_action";
import Features from "../components/features";
import Footer from "../components/footer";

export default function Home() {
    return (
      <Box>
        <Navbar />
        <Hero />
        <CTA />
        <Features />
        <Footer />
      </Box>
    );
  }