import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import RequestDemo from './pages/request-demo';
import Login from './pages/login';
import DemoDay from './pages/demo-day';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/request-demo" element={<RequestDemo />} />
        <Route path="/demo-day" element={<DemoDay />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
