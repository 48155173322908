import React from "react";
import {
  chakra,
  Box,
  Flex,
  useColorModeValue,
  Icon,
  SimpleGrid,
  Button,
  VStack,
} from "@chakra-ui/react";

export default function Features() {
  const Feature = (props) => {
    return (
      <Flex>
        <Icon
          boxSize={5}
          mt={1}
          mr={2}
          color={useColorModeValue("blue.500", "blue.300")}
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clipRule="evenodd"
          ></path>
        </Icon>
        <chakra.p
          fontSize="lg"
          color={useColorModeValue("gray.700", "gray.400")}
          {...props}
        />
      </Flex>
    );
  };

  return (
    <Flex
      //bg={useColorModeValue("#F9FAFB", "gray.600")}
      bg={useColorModeValue("blue.100")}
      p={8}
      w="auto"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        shadow="xl"
        bg={useColorModeValue("white", "gray.800")}
        px={8}
        py={20}
        mx="auto"
        borderWidth='1px' borderRadius='lg' 
      >
        <SimpleGrid
          alignItems="center"
          columns={{ base: 1, lg: 2 }}
          spacingY={{ base: 10, lg: 32 }}
          spacingX={{ base: 10, lg: 24 }}
        >
          <Box>
            <chakra.h2
              mb={3}
              fontSize={{ base: "3xl", md: "4xl" }}
              fontWeight="extrabold"
              textAlign={{ base: "center", sm: "left" }}
              color={useColorModeValue("black")}
              lineHeight="shorter"
              letterSpacing="tight"
            >
              Conoce los Beneficios
            </chakra.h2>
            <chakra.p
              mb={6}
              fontSize={{ base: "lg", md: "xl" }}
              textAlign={{ base: "center", sm: "left" }}
              color={useColorModeValue("gray.600", "gray.500")}
            >
              No pierdas tiempo y comienza a liberar a tus empleados de la
              carga de tomar órdenes por Servi-Carro. Reduce la carga laboral y
              automatiza el proceso de toma de órdenes.
            </chakra.p>
            <Button
              as="a"
              variant="solid"
              w={{ base: "full", sm: "auto" }}
              colorScheme="blue"
              size="lg"
              rounded={'full'}
            >
              Contáctanos Hoy
            </Button>
          </Box>
          <VStack
            direction="column"
            flexGrow={1}
            spacing={5}
            alignItems="start"
          >
            <Feature>Aumenta el volumen de órdenes con menos esfuerzo</Feature>
            <Feature>Mejora el "Average Ticket Value" ofreciendo mejores ofertas siempre</Feature>
            <Feature>Permite operar el Servi-Carro 24/7</Feature>
            <Feature>Ház que tus empleados sean más productivos</Feature>
            <Feature>Acepta órdenes de clientes que hablan inglés u otro idioma</Feature>
            <Feature>Mejora el servicio al cliente sin hacerlos esperar para tomar su orden</Feature>
          </VStack>
        </SimpleGrid>
      </Box>
    </Flex>
  );
}