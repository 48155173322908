import React from "react";
import {
  chakra,
  Box,
  useColorModeValue,
  Stack,
  Image,
  Flex,
} from "@chakra-ui/react";
import ServerIllustration from "./assets/img/server-pana.png";

const CTA = () => {
  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      bg={useColorModeValue("blue.500")}
      px={8}
      py={24}
      mx="auto"
    >
      <Box
        w={{ base: "full", md: 11 / 12, xl: 9 / 12 }}
        mx="auto"
        pr={{ md: 20 }}
      >
        <chakra.h2
          fontSize={{ base: "3xl", sm: "4xl" }}
          fontWeight="extrabold"
          lineHeight="shorter"
          color={useColorModeValue("white", "gray.100")}
          mb={6}
        >
          <chakra.span display="block">¿Cómo funciona</chakra.span>
          <chakra.span
            display="block"
            color={useColorModeValue("white", "gray.500")}
          >
            nuestra Inteligencia Artificial?
          </chakra.span>
        </chakra.h2>
        <chakra.p
          mb={6}
          fontSize={{ base: "lg", md: "xl" }}
          color={useColorModeValue("gray.100", "gray.300")}
        >
          Nuestra Inteligencia Artificial está entrenada en millones de conversaciones
          para poder entender el lenguaje natural y así poder tomar la orden de tus
          clientes en el Servi-Carro. Y mientras más órdenes realice será más inteligente
          mejorando la experiencia de tus clientes con el tiempo.
        </chakra.p>
        <Stack
          direction={{ base: "column", sm: "row" }}
          mb={{ base: 4, md: 8 }}
          spacing={2}
        >
          <Box display="inline-flex" rounded="full" shadow="md">
            <chakra.a
              href="/request-demo"
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              px={5}
              py={3}
              border="solid transparent"
              fontWeight="bold"
              w="full"
              rounded="full"
              color={useColorModeValue("white")}
              bg={useColorModeValue("blue.600", "blue.500")}
              _hover={{
                bg: useColorModeValue("blue.700", "blue.600"),
              }}
            >
              Conoce más
            </chakra.a>
          </Box>
        </Stack>
      </Box>
      <Box w={{ base: "full", md: 10 / 12 }} mx="auto" textAlign="center">
        <Image
          w="full"
          rounded="lg"
          src={ServerIllustration}
          alt="Man working on server"
        />
      </Box>
    </Flex>
  );
};

export default CTA;