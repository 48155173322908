import React from 'react';

import { 
  Center, 
  Container, 
  Card,
  CardBody,
  CardFooter,
  Heading, 
  Text, 
  Button 
} from "@chakra-ui/react";
import { Link } from 'react-router-dom';

function DemoDay() {
  return (
    <Center h="100vh">
      <Container centerContent>
        <Card textAlign="center" width="100%" height="100%">
          <CardBody>
            <Heading size="lg">Fase 1 Gen 2 Demo Day</Heading>
            <Text fontSize="md">!!Gracias por tu interés en Nabori Labs!!</Text>
          </CardBody>
          <CardFooter justifyContent="center">
            <Link to="https://youtu.be/l253m25_iqg?si=pnm-GPuDmFILJiDC">
              <Button colorScheme="blue">Ver Demo</Button>
            </Link>
          </CardFooter>
        </Card>
      </Container>
    </Center>
  );
}

export default DemoDay;
